import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import { navigate } from "@reach/router";
import $ from "jquery"
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format } from 'date-fns'
import { propertyTypes, priceminRangeResiSale, pricemaxRangeResiSaleBanner, priceminRangeResiRent, pricemaxRangeResiRent, bedroomsRange, resiSalesPropertyType, resiLettingsPropertyType, completionRange } from "../../components/common/propertyUtils";

import './LocationSelect.scss'
import SearchListBox from "../predictive-search/search-multiareas";
import AreaListBox from "./../PropertyFilter/AreaList";
import { isEmpty, filter, find, isArray, isString, get, indexOf } from "lodash"
const LocationSelect = (props) => {
    const [areaVal, setAreaVal] = useState('');
    const [areaRefineList, setAreaRefineList] = useState([]);
    const [areasArr, setAreasArr] = useState([]);
    const [areasList, setAreasList] = useState([]);
    const [completionVal, setCompletionVal] = useState('');
    const [guestState, setGuestState] = React.useState(false);
    const [closeState, setCloseState] = React.useState(false);
    const [adultCount, setAdultCount] = React.useState(0);
    const [childrenCount, setChildrenCount] = React.useState(0);
    const [infantCount, setInfantCount] = React.useState(0);
    const [totalCount, setTotalCount] = React.useState(0);
    
    const handleMultiAreaFun = (val) => {
      let areas = [];
      if (isEmpty(find(areasArr, val))) {
          areas = [...areasArr]
          areas.push(val)
          setAreasArr(areas)
      }
  }
  const removeArea = (area, clear = false) => {
      if (!clear) {
          let prevAreas = areasArr.filter(x => x.slug !== area)
          setAreaVal('');
          setAreasArr(prevAreas);
      } else {
          setAreasArr([]);
          setAreaVal('');
          setAreasList([]);
      }
  }

  useEffect(() => {
    setAreasArr([]);
    setAreaVal('');
    setAreasList([]);
    setTotalCount(0)
    setCompletionVal('')
    setGuestState(false)
    setChildrenCount(0)
    setAdultCount(0)
    // setDateRange()
    setShowDatePicker(false)
    setPTypeValue('')
    setPriceValue('')
    setStartDate('')
    setEndDate('')
}, [props.title])

  useEffect(() => {
    setTotalCount(adultCount + childrenCount + infantCount)
}, [adultCount, childrenCount, infantCount])
//   React.useEffect(() => {
//     var mind = format(new Date(state[0].startDate), "yyyy-MM-dd")
//     var maxd = format(new Date(state[0].endDate), "yyyy-MM-dd")
//     refine({
//         min: Date.parse(mind),
//         max: Date.parse(maxd),
//     })
// }, [state])
const [showDatePicker, setShowDatePicker] = useState(false)
    const [locationvalue, setLocationValue] = useState('dubai');
    const [ptypevalue, setPTypeValue] = useState('');
    const [pricevalue, setPriceValue] = useState('');
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
  
    const [startDisplayDate, setStartDisplayDate] = useState("")
    const [endDisplayDate, setEndDisplayDate] = useState("")
    const handleDateChange = range => {
        setDateRange(range.selection)
        const start = formatDate(range.selection.startDate)
        const end = formatDate(range.selection.endDate)
    
        const startDispaly = formatDateDisplay(range.selection.startDate)
        const endDispaly = formatDateDisplay(range.selection.endDate)
    
        setStartDate(start)
        setEndDate(end)
    
        setStartDisplayDate(startDispaly)
        setEndDisplayDate(endDispaly)
    
      }
      const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      })
    const formatDate = date => {
        const formatedDate = format(new Date(date), "yyyy-MM-dd")
        return formatedDate
      }
    const formatDateDisplay = date => {
        const formatedDate = format(new Date(date), "dd-MM-yyyy")
        return formatedDate
      }
    const tabbingData = props.tabbingData;
    let locationOptions = [{
        value: "", label: "Location"
    },
    {
        value: "akoya", label: "Akoya (DAMAC Hills)"
    },
    {
        value: "al-barari", label: "Al Barari"
    },
    {
        value: "bluewaters-island", label: "Bluewaters Island"
    },
    {
        value: "dubai-harbour", label: "Dubai Harbour"
    },
    {
        value: "dubai-hills-estate", label: "Dubai Hills Estate"
    },
    {
        value: "dubai-marina", label: "Dubai Marina"
    },
    {
        value: "emaar-beachfront", label: "EMAAR Beachfront"
    },
    {
        value: "emaar-beachfront", label: "EMAAR"
    },
    {
        value: "jbr", label: "JBR"
    },
    {
        value: "jumeirah-lake-towers", label: "Jumeirah Lake Towers"
    },
    {
        value: "palm-jumeirah", label: "Palm Jumeirah"
    },
    {
        value: "town-square", label: "Town Square"
    }]

    function searchclick() {
        var adultsValue = adultCount
        var childrenValue = childrenCount
        var areaValue = []
        if (areaRefineList) {
            let areasToRefine = []
            // let areasForUrl = []
            areasArr.map((a) => {
                if (a) {
                    areasToRefine.push(a.slug)
                    // areasForUrl.push(a.slug)
                }
            })
            setAreaRefineList(areasToRefine)
            areaValue = areasToRefine
        }
        var url = ''
        var p_type_url = '/property/for-sale'
        if (areaValue?.length > 0) {
            url += 'in-' + areaValue.join("-and-") + '/'
        }
        else {
            url += 'in-dubai/'
        }
        if (ptypevalue) {
            url += 'type-' + ptypevalue + '/'
        }
        if (pricevalue) {
            url += 'under-' + pricevalue + '/'
        }
        if (props.title == 'Rent') {
            p_type_url = '/property/to-rent'
        }  if (props.title == 'Holiday Lets') {
            p_type_url = '/holiday-lets'
            if (adultsValue > 0) {
                url += adultsValue + "-adults-or-more/";
            }
    
            if (childrenValue > 0) {
                url += childrenValue + "-childrens-or-more/";
            }
    
            if(startDate) {
                url += "check-in-"+ startDate +"/";
            }
    
            if(endDate) {
                url += "check-out-"+ endDate +"/";
            }
        } else if (props.title == 'Off Plan') {
            p_type_url = '/off-plan-property/for-sale'
        }

        navigate(p_type_url + '/' + url)
    }

    

    return (
        <>
        <div className={`location-wrap search-wrapper ${props?.isheader ? "head-search" : ""}`}>
            <div className='dropdown-wrap review-dropdown'>
                
                {/* <i className='icon-location'></i> */}
                {/* <select className='banner-select'>
                    {
                        tabbingData.location?.map((item, index) => {
                            return <option value={item} key={index}>{item}</option>
                        })
                    }
                </select> */}
                {/* <Select options={locationOptions} id="location" onChange={(e) => setLocationValue(e.value)} defaultValue={locationOptions[0]} className="react-select" classNamePrefix="react-select" isSearchable={true} /> */}
                <SearchListBox areaVal={areaVal} setAreaVal={setAreaVal} setAreasList={setAreasList} handleMultiAreaFun={handleMultiAreaFun} autosuggestInputProps={{ className: 'form-control react-autosuggest__input refine-autosuggest-input' }} />
          
            </div>
            {(tabbingData.propertyType) && <div className='d-none d-xl-block dropdown-wrap property-type review-dropdown'>
                {/* <select className='banner-select'>
                    {
                        tabbingData.propertyType?.map((item, index) => {
                            return <option value={item} key={index}>{item}</option>
                        })
                    }
                </select> */}
{
// props.title == 'Off Plan' ?
// <Select
//                                     options={completionRange}
//                                     placeholderValue={"Completion Date"}
//                                     value={completionRange.find(obj => obj.value === completionVal)}
//                                     onChange={e => setCompletionVal(e.value)}
//                                     isSearchable={false}
//                                     className="react-select filter-opt"
//                                     classNamePrefix="react-select"
//                                 />
// :
 props.title == 'Holiday Lets' ?
<div className='date-range'>
<div
className={showDatePicker ? "datechoose active" : "datechoose"}
onClick={() => {
  setShowDatePicker(prev => !prev);
  setGuestState(false)
}}
>
{startDisplayDate ? 
    <>
    {startDisplayDate} to {endDisplayDate}
    </>
    : 
    <>Check in - Check out</>}
    </div>
{showDatePicker && (
<div className='date-picker'>
<DateRange
                editableDateInputs={true}
                onChange={handleDateChange}
                moveRangeOnFirstSelection={false}
                ranges={[dateRange]}
                weekdayDisplayFormat="EEEEE"
                dateDisplayFormat="dd-MM-yyyy"
                showClearDate={true}
                showDateDisplay={false}
                minDate={new Date()}
            />
            </div>)}
            </div>
:

                <Select options={resiSalesPropertyType} onChange={(e) => setPTypeValue(e.value)} defaultValue={resiSalesPropertyType[0]} className="react-select" classNamePrefix="react-select" isSearchable={true} />
            }
            </div>}
            {(tabbingData.maxPrice) && <div className='d-none d-xl-block dropdown-wrap maximum-price review-dropdown'>
                {/* <select className='banner-select'>
                    {
                        tabbingData.maxPrice?.map((item, index) => {
                            return <option value={item} key={index}>{item}</option>
                        })
                    }
                </select> */}
{props.title == 'Holiday Lets' ?
<div className='guest-btn'>
                            <div className="guest-number d-none d-xl-flex" onClick={() => {setGuestState(prev => !prev);setShowDatePicker(false)}}>
                                {totalCount ? totalCount : null} Guests <i className={guestState ? "icon-dropdown active" : "icon-dropdown "}></i>
                            </div>
                            <div className={guestState ? "guest-dropdown d-block" : "guest-dropdown d-none"}>
                                <div className="guest-wrap">
                                    <div className="ltl-guest">
                                        <span className="guest-category">Adults</span>
                                        <span className="guest-description">(Ages 16 or above)</span>
                                    </div>
                                    <div className="rtl-guest">
                                        <div className="minus-btn" onClick={() => (adultCount > 0 && setAdultCount(adultCount - 1))}>-</div>
                                        <span className="btn-value">
                                            {adultCount}
                                        </span>
                                        <div className="plus-btn" onClick={() => setAdultCount(adultCount + 1)}>+</div>
                                    </div>
                                </div>
                                <div className="guest-wrap">
                                    <div className="ltl-guest">
                                        <span className="guest-category">Children</span>
                                        <span className="guest-description">(Ages 0-12 or above)</span>
                                    </div>
                                    <div className="rtl-guest">
                                        <div className="minus-btn" onClick={() => (childrenCount > 0 && setChildrenCount(childrenCount - 1))}>-</div>
                                        <span className="btn-value">
                                            {childrenCount}
                                        </span>
                                        <div className="plus-btn" onClick={() => setChildrenCount(childrenCount + 1)}>+</div>
                                    </div>
                                </div>
                                {/* <div className="guest-wrap">
                                    <div className="ltl-guest">
                                        <span className="guest-category">Infants</span>
                                        <span className="guest-description">(Under 2)</span>
                                    </div>
                                    <div className="rtl-guest">
                                        <div className="plus-btn" onClick={() => (infantCount > 0 && setInfantCount(infantCount - 1))}>-</div>
                                        <span className="btn-value">
                                            {infantCount}
                                        </span>
                                        <div className="minus-btn" onClick={() => setInfantCount(infantCount + 1)}>+</div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
:

                <Select options={props?.title === "Rent" ? pricemaxRangeResiRent : pricemaxRangeResiSaleBanner} onChange={(e) => setPriceValue(e.value)} defaultValue={pricemaxRangeResiSaleBanner[0]} className="react-select" classNamePrefix="react-select" isSearchable={true} />
            }
            </div>}
            <button aria-label="search-btn" className='search-btn' onClick={() => {searchclick();setShowDatePicker(false)}}>
                <i className='icon-search'></i>
            </button>
        </div>
        <AreaListBox className={props?.landingpage ? "home-banner no-border" : "home-banner"} areas={areasArr} removeArea={removeArea} />        
        </>
    )
}



export default LocationSelect